import {createRouter, createWebHistory} from "vue-router";
import {user_store} from "../user_store";
import {computed} from "vue";

function automaticUserLogin() {
    user_store.connect_user_to_api().catch(() => console.log('Automatic login failed'))
}

const isPhysician = () => {
  return user_store.getState().physician_groups_of_my_institution?.some(item => user_store.getState().groups.includes(item))
}

const has_dienstmodell = () => (user_store.getState().institution.data ? Object.keys(user_store.getState().institution.data).includes('Dienstmodell') : false)


const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("../views/Home.vue"),
        beforeEnter: [automaticUserLogin]
    },
    {
        path: "/wishlist",
        name: "wishlist",
        component: () => import("../components/DienstplanCalendar.vue"),
        meta: {requiresAuth: true},
    },
    {
        path: "/dienstplan",
        name: "dienstplan",
        component: () => import("../components/DienstplanView.vue"),
        meta: {requiresAuth: true},
    },
        {
        path: "/dienstbroker",
        name: "dienstbroker",
        component: () => import("../components/DienstplanTrader.vue"),
        meta: {requiresAuth: true},
    },
            {
        path: "/takeovercandidates/:tradeid",
        name: "takeovercandidates",
        component: () => import("../components/DienstplanTraderTakeoverCandidates.vue"),
        meta: {requiresAuth: true},
                props: true
    },
    {
        path: "/abrechnung",
        name: "abrechnung",
        component: () => import("../components/AbrechnungsView.vue"),
        meta: {requiresAuth: true},
    },
    {
        path: "/inaugurate",
        name: "inaugurate",
        component: () => import("../components/DienstplanGenerate.vue"),
        meta: {requiresAuth: true},
    },
        {
        path: "/changeholidays",
        name: "changeholidays",
        component: () => import("../components/DienstplanFeiertageChangeView.vue"),
        meta: {requiresAuth: true},
    },
            {
        path: "/automation-settings",
        name: "automation-settings",
        component: () => import("../components/AutomationSettingsCard.vue"),
        meta: {requiresAuth: true},
    },
    {
        path: "/schedulize",
        name: "schedulize",
        component: () => import("../components/SchedulizerComponent.vue"),
        meta: {requiresAuth: true},
    },
        {
        path: "/statistics",
        name: "statistics",
        component: () => import("../components/StatisticsView.vue"),
        meta: {requiresAuth: true},
    },
            {
        path: "/mystatistics",
        name: "mystatistics",
        component: () => import("../components/MyStatistics.vue"),
        meta: {requiresAuth: true},
    },
    {
        path: "/verwaltungsprofil",
        name: "verwaltungsprofil",
        component: () => import("../components/VerwaltungProfil.vue"),
        meta: {requiresAuth: true},
    },
    {
        path: "/mitarbeiterzuordnung",
        name: "mitarbeiterzuordnung",
        component: () => import("../components/MitarbeiterZuordnung.vue"),
        meta: {requiresAuth: true},
    },
        {
        path: "/mitarbeitereditor",
        name: "mitarbeitereditor",
        component: () => import("../components/MitarbeiterEditor.vue"),
        meta: {requiresAuth: true},
    },
    {
        path: "/berechtigungen",
        name: "berechtigungen",
        component: () => import("../components/MitarbeiterBerechtigung.vue"),
        meta: {requiresAuth: true},
    },
    {
        path: "/dienstmodell",
        name: "dienstmodell",
        component: () => import("../components/DienstModellView.vue"),
        meta: {requiresAuth: true},
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../components/LoginForm.vue"),
        props: {startnew: false},
    },
    {
        path: "/account",
        name: "account",
        component: () => import("../components/MeinAccountView.vue"),
        meta: {requiresAuth: true},
    },
        {
        path: "/mydienzt",
        name: "mydienzt",
        component: () => import("../components/MeinDienztView.vue"),
            meta: {requiresAuth: true},
    },
    {
        path: "/forgotpassword",
        name: "forgotpassword",
        component: () => import("../components/ForgotPassword.vue"),
        props: {email: ''}
    },
    {
        path: "/changepassword",
        name: "changepassword",
        component: () => import("../components/ChangePasswordForm.vue"),
        meta: {requiresAuth: true},
    },
    {
        path: "/logout",
        name: "logout",
        component: () => import("../components/LogoutForm.vue"),
        meta: {requiresAuth: true},
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if ((to.name==='login') || (to.name==='forgotpassword')) {
        next()
    } else {
        if (to.meta.requiresAuth && !user_store.getState().authenticated) {
            // this route requires auth, check if logged in
            // if not, redirect to login page.
            user_store.connect_user_to_api().then(() => {
                next()
            }).catch(() =>
                next({
                    path: '/login',
                    // save the location we were at to come back later
                    query: {...to.query, redirect: to.fullPath},
                }))
        } else {
            if (to.name != 'home') {
                // criteria to relay to home instead of target
                if (user_store.getState().additional_flags.check_datenschutz &&
                    user_store.getState().additional_flags.check_nutzungsbedingungen &&
                    user_store.getState().is_emailvalidated &&
                    ((Object.keys(user_store.getState().data_encrypted).includes('Vertrags_Arbeitszeiten') && (isPhysician())) || (!isPhysician())) &&
                    ((Object.keys(user_store.getState().data_dienzt_preferences).includes('dienstPreferences')) && (isPhysician() && (has_dienstmodell())) ||  (!isPhysician()) || (!has_dienstmodell()))
                ) {
                    next()
                } else {
                    next({
                        path: '/',
                        query: {...to.query, redirect: to.fullPath},
                    })
                }
            } else {
                next()
            }
        }
    }
})


export default router;
